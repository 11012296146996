export const GET_FCL_TABLE_DATA = 'GET_FCL_TABLE_DATA';
export const GET_FCL_LOADER = 'GET_FCL_LOADER';
export const GET_FCL_TABLE_DATA_SUCCESS = 'GET_FCL_TABLE_DATA_SUCCESS';
export const GET_FCL_TABLE_DATA_FAIL = 'GET_FCL_TABLE_DATA_FAIL';

export const GET_FCL_FREIGHT_VIEW_DATA = 'GET_FCL_FREIGHT_VIEW_DATA';
export const GET_FCL_FREIGHT_VIEW_DATA_SUCCESS = 'GET_FCL_FREIGHT_VIEW_DATA_SUCCESS';
export const GET_FCL_FREIGHT_VIEW_LOADER = 'GET_FCL_FREIGHT_VIEW_LOADER';

export const GET_FCL_SURCHARGE_VIEW_DATA = 'GET_FCL_SURCHARGE_VIEW_DATA';
export const GET_FCL_SURCHARGE_VIEW_DATA_SUCCESS = 'GET_FCL_SURCHARGE_VIEW_DATA_SUCCESS';
export const GET_FCL_SURCHARGE_VIEW_LOADER = 'GET_FCL_SURCHARGE_VIEW_LOADER';

export const GET_FCL_DESTINATION_DATA = 'GET_FCL_DESTINATION_DATA';
export const GET_FCL_DESTINATION_DATA_SUCCESS = 'GET_FCL_DESTINATION_DATA_SUCCESS';

export const UPDATE_FCL_TABLE_DATA = 'UPDATE_FCL_TABLE_DATA';
export const UPDATE_FCL_SWITCH = 'UPDATE_FCL_SWITCH';
export const ADD_FCL_DATA = 'ADD_FCL_DATA';
export const UPDATE_FCL_ACTIVE_TAB = 'UPDATE_FCL_ACTIVE_TAB';
export const UPDATE_INLAND_ACTIVE_TAB = 'UPDATE_INLAND_ACTIVE_TAB';

export const GET_LCL_TABLE_DATA = 'GET_LCL_TABLE_DATA';
export const GET_LCL_TABLE_DATA_SUCCESS = 'GET_LCL_TABLE_DATA_SUCCESS';
export const GET_LCL_TABLE_DATA_FAIL = 'GET_LCL_TABLE_DATA_FAIL';
export const UPDATE_LCL_SWITCH = 'UPDATE_LCL_SWITCH';

// procurement/ocean freight/port local charges
export const GET_PORTLOCALCHARGES_TABLE_DATA = 'GET_PORTLOCALCHARGES_TABLE_DATA';
export const GET_PORTLOCALCHARGES_TABLE_DATA_SUCCESS = 'GET_PORTLOCALCHARGES_TABLE_DATA_SUCCESS';
export const GET_PORTLOCALCHARGES_TABLE_DATA_FAIL = 'GET_PORTLOCALCHARGES_TABLE_DATA_FAIL';
export const FILTER_PORTLOCALCHARGES_DATA = 'FILTER_PORTLOCALCHARGES_DATA';
//-----------------------------------

export const GET_WAYBILL_TABLE_DATA = 'GET_WAYBILL_TABLE_DATA';
export const GET_WAYBILL_TABLE_DATA_SUCCESS = 'GET_WAYBILL_TABLE_DATA_SUCCESS';
export const GET_WAYBILL_TABLE_DATA_LOADER="GET_WAYBILL_TABLE_DATA_LOADER"
export const GET_WAYBILL_TABLE_DATA_FAIL = 'GET_WAYBILL_TABLE_DATA_FAIL';
export const UPDATE_AIRWAYBILL_SWITCH = 'UPDATE_AIRWAYBILL_SWITCH';
export const ADD_AIRWAYBILL_DATA = 'ADD_AIRWAYBILL_DATA';
export const ADD_AIRCONSOLE_DATA = 'ADD_AIRCONSOLE_DATA';
export const GET_WAYBILL_TABLE_DATA_BY_ID = 'GET_WAYBILL_TABLE_DATA_BY_ID';
export const GET_WAYBILL_TABLE_DATA_BY_ID_RESPONSE = 'GET_WAYBILL_TABLE_DATA_BY_ID_RESPONSE';
export const GET_CONSOLE_TABLE_DATA = 'GET_CONSOLE_TABLE_DATA';
export const GET_CONSOLE_TABLE_DATA_SUCCESS = 'GET_CONSOLE_TABLE_DATA_SUCCESS';
export const GET_CONSOLE_TABLE_DATA_LOADER="GET_CONSOLE_TABLE_DATA_LOADER"
export const GET_CONSOLE_TABLE_DATA_FAIL = 'GET_CONSOLE_TABLE_DATA_FAIL';
export const UPDATE_AIRCONSOLE_SWITCH = 'UPDATE_AIRCONSOLE_SWITCH';

//---------
//MAWB
export const POST_CARRIER_DATA = 'POST_CARRIER_DATA';
export const MAWB_FRIGHT_FAILD_DATA_TYPE="MAWB_FRIGHT_FAILD_DATA_TYPE"
export const MAWB_FRIGHT_FAILD_POPUP_TYPE='MAWB_FRIGHT_FAILD_POPUP_TYPE'
export const GET_ALL_MAWB_FRIGHT_DETAILS="GET_ALL_MAWB_FRIGHT_DETAILS"
export const GET_ALL_MAWB_FRIGHT_DETAILS_LOADER="GET_ALL_MAWB_FRIGHT_DETAILS_LOADER"
export const GET_ALL_MAWB_FRIGHT_DETAILS_SUCCESS='GET_ALL_MAWB_FRIGHT_DETAILS_SUCCESS'
export const SAVE_MAWB_FRIGHT_RP_DATA='SAVE_MAWB_FRIGHT_RP_DATA'
export const SAVE_MAWB_FRIGHT_RP_DATA_SUCCESS='SAVE_MAWB_FRIGHT_RP_DATA_SUCCESS'

//CONSOLE

export const POST_CARRIER_DATA_CONSOLE = 'POST_CARRIER_DATA_CONSOLE';
export const CONSOLE_FRIGHT_FAILD_DATA_TYPE="CONSOLE_FRIGHT_FAILD_DATA_TYPE"
export const CONSOLE_FRIGHT_FAILD_POPUP_TYPE='CONSOLE_FRIGHT_FAILD_POPUP_TYPE'
export const GET_ALL_CONSOLE_FRIGHT_DETAILS="GET_ALL_CONSOLE_FRIGHT_DETAILS"
export const GET_ALL_CONSOLE_FRIGHT_DETAILS_LOADER="GET_ALL_CONSOLE_FRIGHT_DETAILS_LOADER"
export const GET_ALL_CONSOLE_FRIGHT_DETAILS_SUCCESS='GET_ALL_CONSOLE_FRIGHT_DETAILS_SUCCESS'
export const SAVE_CONSOLE_FRIGHT_RP_DATA='SAVE_CONSOLE_FRIGHT_RP_DATA'
export const SAVE_CONSOLE_FRIGHT_RP_DATA_SUCCESS='SAVE_CONSOLE_FRIGHT_RP_DATA_SUCCESS'

//TENANT CARGO MODE
export const GET_ALL_TENANT_CARGO_MODE="GET_ALL_TENANT_CARGO_MODE"
export const GET_ALL_TENANT_CARGO_MODE_SUCCESS="GET_ALL_TENANT_CARGO_MODE_SUCCESS"

export const BLANK_CARRIER_DATA = 'BLANK_CARRIER_DATA';
export const BLANK_FCL_CARRIER_DATA = 'BLANK_FCL_CARRIER_DATA';
export const BLANK_FCL_FREIGHT_DATA = 'BLANK_FCL_FREIGHT_DATA';
export const BLANK_SURCHARGE_DATA = 'BLANK_SURCHARGE_DATA';
export const GET_UPLOAD_STATUS = 'GET_UPLOAD_STATUS';
export const GET_UPLOAD_STATUS_SUCCESS = 'GET_UPLOAD_STATUS_SUCCESS';
// ------------------------- 
// FCL
export const UPLOAD_FCL_CARRIER_DATA = 'UPLOAD_FCL_CARRIER_DATA';
export const UPLOAD_FCL_FREIGHT = 'UPLOAD_FCL_FREIGHT';
export const UPLOAD_FCL_FREIGHT_V2 = 'UPLOAD_FCL_FREIGHT_V2';
export const GET_OCEAN_FRIGHT_DETAILS="GET_OCEAN_FRIGHT_DETAILS"
export const POST_OCEAN_FRIGHT_DETAILS="POST_OCEAN_FRIGHT_DETAILS";
export const POST_OCEAN_FRIGHT_DETAILS_SUCCESS="POST_OCEAN_FRIGHT_DETAILS_SUCCESS";
export const FCL_FREIGHT_TABLE_DATA_SUCCESS="FCL_FREIGHT_TABLE_DATA_SUCCESS";
export const GET_FCL_FREIGHT_DETAILS_SUCCESS="GET_FCL_FREIGHT_DETAILS_SUCCESS";
export const GET_OCEAN_FRIGHT_TABLE_DATA="GET_OCEAN_FRIGHT_TABLE_DATA"
export const GET_FCL_OCEAN_SURCHARGES_DETAILS="GET_FCL_OCEAN_SURCHARGES_DETAILS"
export const GET_FCL_OCEAN_SURCHARGES_DETAILS_SUCCESS="GET_FCL_OCEAN_SURCHARGES_DETAILS_SUCCESS";
export const POST_OCEAN_FCL_SURCHARGE_DETAILS="POST_OCEAN_FCL_SURCHARGE_DETAILS";

export const POST_FCL_OCEAN_SURCHARGES="POST_FCL_OCEAN_SURCHARGES";

export const UPLOAD_FCL_SURCHARGE = 'UPLOAD_FCL_SURCHARGE';
export const GET_FCL_CHARGE_ID = 'GET_FCL_CHARGE_ID';
export const FCL_FREIGHT_FAILD_POPUP_TYPE = 'FCL_FREIGHT_FAILD_POPUP_TYPE';
export const FCL_FREIGHT_FAILD_DATA_TYPE = 'FCL_FREIGHT_FAILD_DATA_TYPE';
export const GET_FCL_CURRENT_VERSION_TYPE = 'GET_FCL_CURRENT_VERSION_TYPE';

// FCL PORT & LOCAL CHARGES
export const GET_FCL_PLCHARGES_LOADER = 'GET_FCL_PLCHARGES_LOADER';
export const UPLOAD_FCL_PORTLOCALCHARGES = 'UPLOAD_FCL_PORTLOCALCHARGES';
export const GET_FCL_PLCHARGES_RESPONCE='GET_FCL_PLCHARGES_RESPONCE'

// AIR PORT & LOCAL CHARGES
export const GET_AIR_PORT_LOCAL_CHARGES_DATA="GET_AIR_PORT_LOCAL_CHARGES_DATA";
export const GET_AIR_PORT_LOCAL_CHARGES_SUCCESS="GET_AIR_PORT_LOCAL_CHARGES_SUCCESS";
export const GET_AIR_PORT_LOCAL_CHARGES_LOADER="GET_AIR_PORT_LOCAL_CHARGES_LOADER";
export const UPLOAD_AIR_PORT_LOCAL_DATA="UPLOAD_AIR_PORT_LOCAL_DATA";
export const GET_AIR_PORT_LOCAL_CHARGES_BY_ID="GET_AIR_PORT_LOCAL_CHARGES_BY_ID"
export const GET_AIR_PORT_LOCAL_CHARGES_BY_ID_SUCCESS="GET_AIR_PORT_LOCAL_CHARGES_BY_ID_SUCCESS";

//AIR LINE CHARGES..
export const GET_AIR_LINE_CHARGES_DATA="GET_AIR_LINE_CHARGES_DATA";
export const GET_AIR_LINE_CHARGES_SUCCESS="GET_AIR_LINE_CHARGES_SUCCESS";
export const GET_AIR_LINE_CHARGES_LOADER="GET_AIR_LINE_CHARGES_LOADER";
export const UPLOAD_AIR_LINE_DATA="UPLOAD_AIR_LINE_DATA";
export const GET_AIR_LINE_CHARGES_BY_ID="GET_AIR_LINE_CHARGES_BY_ID";
export const GET_AIR_LINE_CHARGES_BY_ID_SUCCESS="GET_AIR_LINE_CHARGES_BY_ID_SUCCESS";

// FCL INLAND Charges
export const GET_FCL_INLAND_TABLE_DATA = 'GET_FCL_INLAND_TABLE_DATA';
export const GET_FCL_INLAND_TABLE_DATA_SUCCESS = 'GET_FCL_INLAND_TABLE_DATA_SUCCESS';
export const GET_FCL_INLAND_TABLE_DATA_FAIL = 'GET_FCL_INLAND_TABLE_DATA_FAIL';
export const GET_FCL_INLAND_LOADER = 'GET_FCL_INLAND_LOADER';

export const GET_FCL_INLAND_FREIGHT_ACTION = 'GET_FCL_INLAND_FREIGHT_ACTION';
export const GET_FCL_INLAND_FREIGHT_ACTION_SUCCESS = 'GET_FCL_INLAND_FREIGHT_ACTION_SUCCESS';
export const GET_FCL_INLAND_FREIGHT_LOADER = 'GET_FCL_INLAND_FREIGHT_LOADER';

export const GET_FCL_INLAND_SURCHARGE_ACTION = 'GET_FCL_INLAND_SURCHARGE_ACTION';
export const GET_FCL_INLAND_SURCHARGE_ACTION_SUCCESS = 'GET_FCL_INLAND_SURCHARGE_ACTION_SUCCESS';
export const GET_FCL_INLAND_SURCHARGE_LOADER = 'GET_FCL_INLAND_SURCHARGE_LOADER';

export const UPLOAD_FCL_INLAND_CARRIER_DATA = 'UPLOAD_FCL_INLAND_CARRIER_DATA';
export const UPLOAD_FCL_INLAND_FREIGHT_DATA = 'UPLOAD_FCL_INLAND_FREIGHT_DATA';
export const UPLOAD_FCL_INLAND_SURCHARGE_DATA = 'UPLOAD_FCL_INLAND_SURCHARGE_DATA';
export const GET_FCL_INLAND_CHARGE_ID = 'GET_FCL_INLAND_CHARGE_ID';
export const FCL_INLAND_FAILD_POPUP_TYPE = 'FCL_INLAND_FAILD_POPUP_TYPE';
export const FCL_INLAND_FAILD_DATA_TYPE = 'FCL_INLAND_FAILD_DATA_TYPE';

export const UPDATE_INLAND_SWITCH = 'UPDATE_INLAND_SWITCH';
export const ADD_INLAND_DATA = 'ADD_INLAND_DATA';

export const GET_CONSOLE_TABLE_DATA_BY_ID = 'GET_CONSOLE_TABLE_DATA_BY_ID'
export const GET_CONSOLE_TABLE_DATA_SUCCESS_BY_ID = 'GET_CONSOLE_TABLE_DATA_SUCCESS_BY_ID'