export const GET_QUOTATION_DATA = 'GET_QUOTATION_DATA'
export const GET_QUOTATION_DATA_SUCCESS = 'GET_QUOTATION_DATA_SUCCESS'
export const GET_QUOTATION_DATA_FAIL = 'GET_QUOTATION_DATA_FAIL'
export const FILTER_QUOTATION_DATA = 'FILTER_QUOTATION_DATA'

export const UPDATE_SEARCH_QUOTATION_DATA = 'UPDATE_SEARCH_QUOTATION_DATA'
export const UPDATE_SEARCH_QUOTATION_SWAP = 'UPDATE_SEARCH_QUOTATION_SWAP'
export const UPDATE_SEARCH_QUOTATION_DATE = 'UPDATE_SEARCH_QUOTATION_DATE'
export const UPDATE_CONTAINERTYPE_CONFIRM = 'UPDATE_CONTAINERTYPE_CONFIRM'
export const UPDATE_CONTAINER_CHANGE = 'UPDATE_CONTAINER_CHANGE'
export const UPDATE_VALUE_BLANK = 'UPDATE_VALUE_BLANK'
export const SEARCH_QUOTATION_BLANK = 'SEARCH_QUOTATION_BLANK'

// export const GET_QUOTATION_RESULT_DATA = 'GET_QUOTATION_RESULT_DATA'
// export const GET_QUOTATION_RESULT_DATA1 = 'GET_QUOTATION_RESULT_DATA1'
// export const GET_QUOTATION_RESULT_DATA2 = 'GET_QUOTATION_RESULT_DATA2'
// export const GET_QUOTATION_RESULT_DATA3 = 'GET_QUOTATION_RESULT_DATA3'
// export const GET_QUOTATION_RESULT_SUCCESS = 'GET_QUOTATION_RESULT_SUCCESS'
// export const GET_QUOTATION_RESULT_FAIL = 'GET_QUOTATION_RESULT_FAIL'

// export const UPDATE_QUOTATION_RESULT_DETAILS = 'UPDATE_QUOTATION_RESULT_DETAILS'
// export const QUOTATION_RESULT_SELECTED = 'QUOTATION_RESULT_SELECTED'
// export const QUOTATION_RESULT_SELECTED_BLANK = 'QUOTATION_RESULT_SELECTED_BLANK'
// export const QUOTATION_RESULT_UPDATE = 'QUOTATION_RESULT_UPDATE'
// export const CONFIRM_PREVIEW_DATA = 'CONFIRM_PREVIEW_DATA'

export const GET_INQUIRY_DATA = 'GET_INQUIRY_DATA';
export const GET_INQUIRY_DATA_SUCCESS = 'GET_INQUIRY_DATA_SUCCESS';
export const GET_INQUIRY_DATA_LOADER="GET_INQUIRY_DATA_LOADER";
export const GET_INQUIRY_DATA_FAIL = 'GET_INQUIRY_DATA_FAIL';
export const FILTER_INQUIRY_DATA = 'FILTER_INQUIRY_DATA';

export const GET_INQUIRY_CUSTOMER_SUMMARY_DATA='GET_INQUIRY_CUSTOMER_SUMMARY'
export const GET_INQUIRY_CUSTOMER_SUMMARY_DATA_SUCCESS='GET_INQUIRY_CUSTOMER_SUMMARY_DATA_SUCCESS'

export const GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA='GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA'
export const GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA_SUCCESS='GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA_SUCCESS'

export const GET_INQUIRY_EXPORT_SUMMARY_DATA='GET_INQUIRY_EXPORT_SUMMARY_DATA'
export const GET_INQUIRY_EXPORT_SUMMARY_DATA_SUCCESS='GET_INQUIRY_EXPORT_SUMMARY_DATA_SUCCESS'

export const GET_INQUIRY_IMPORT_SUMMARY_DATA='GET_INQUIRY_IMPORT_SUMMARY_DATA'
export const GET_INQUIRY_IMPORT_SUMMARY__DATA_SUCCESS='GET_INQUIRY_IMPORT_SUMMARY__DATA_SUCCESS'

export const GET_INQUIRY_SUMMARY_DATA='GET_INQUIRY_SUMMARY_DATA'
export const GET_INQUIRY_SUMMARY_DATA_SUCCESS='GET_INQUIRY_SUMMARY_DATA_SUCCESS'
