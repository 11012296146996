export const GET_PARTIES_CUSTOMER_DETAILS_TYPE = "GET_PARTIES_CUSTOMER_DETAILS_TYPE";
export const GET_PARTIES_CUSTOMER_DETAILS_TYPE_SUCCESS = "GET_PARTIES_CUSTOMER_DETAILS_TYPE_SUCCESS";
export const GET_CUSTOMER_LOADER = "GET_CUSTOMER_LOADER";


export const UPLOAD_CUSTOMER_COMPANYDATA_TYPE = "UPLOAD_CUSTOMER_COMPANYDATA_TYPE";
export const UPLOAD_CUSTOMER_CONTACT_TYPE = "UPLOAD_CUSTOMER_CONTACT_TYPE";
export const UPLOAD_CUSTOMER_DOCUMENT_TYPE = "UPLOAD_CUSTOMER_DOCUMENT_TYPE";

export const GET_CUSTOMERS_ID = "GET_CUSTOMERS_ID";

export const GET_CUSTOMER_BY_ID= "GET_CUSTOMER_BY_ID"
export const GET_CUSTOMER_BY_ID_SUCCESS= "GET_CUSTOMER_BY_ID_SUCCESS"
