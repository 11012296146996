export const department = [
  { label: "Accounts", value: "ACCOUNTS"},
  { label: "Sales", value: "SALES"},
  { label: "Finance", value: "FINANCE"},
  { label: "Management", value: "MANAGEMENT"},
  { label: "Primary", value: "PRIMARY"}
];

export const designation = [
  { label: "Executive", value: "EXECUTIVE".toUpperCase() },
  { label: "Asst. Manager", value: "ASST_MANAGER".toUpperCase() },
  { label: "Manager", value: "MANAGER".toUpperCase() },
  { label: "Sr. Manager", value: "SR_MANAGERr".toUpperCase() },
  { label: "AVP", value: "AVP".toUpperCase() },
  { label: "VP", value: "VP".toUpperCase() },
  { label: "President", value: "PRESIDENT".toUpperCase() },
  { label: "Director", value: "DIRECTOR".toUpperCase() },
  { label: "CEO", value: "CEO".toUpperCase() },
  { label: "COO", value: "COO".toUpperCase() },
  { label: "MD", value: "MD".toUpperCase() }
];

export const entityType = [
  { label: "PRIVATE LTD", value: "PRIVATE_LTD"},
  { label: "PUBLIC LTD", value: "PUBLIC_LTD"},
  { label: "SINGLE DIRECTOR", value: "SINGLE_DIRECTOR"},
  { label: "LLP", value: "LLP"},
  { label: "PROPRIETORSHIP", value: "PROPRIETORSHIP"},
];


export const industryType = [
  { label: "Supply Chain", value: "SUPPLY_CHAIN" },
  { label: "Software services", value: "SOFTWARE_SERVICE"},
  { label: "Agriculture", value: "AGRICULTURE"},
  { label: "Manufacturing", value: "MANUFACTURING"},
  { label: "Transportation", value: "TRANSPORTATION"}
];

export const vendorTypeOptions = [
  { label: "ALL", value: "ALL" },
  { label: "CARRIER", value: "CARRIER" },
  { label: "NVOCC", value: "NVOCC" },
  { label: "AGENT", value: "OVERSEAS_AGENT" },
  { label: "CHA", value: "CHA" },
  { label: "TRANSPORTER", value: "TRANSPORTER" },
  { label: "CO LOADER", value: "CO_LOADER" },
  { label: "LOCAL AGENT", value: "LOCAL_AGENT" },
  { label: "MANPOWER AGENT", value: "MANPOWER_AGENT" },
]

export const serviceTypeOptions = [
  { label: "Ocean", value: "OCEAN" },
  { label: "Air", value: "AIR"},
];
